import { Notify } from 'quasar'

type NotifyOptions = {
  message: string
  actions?: NotifyAction[]
}

type NotifyAction = {
  label: string
  handler: () => void
}

export const notify = {
  notify: (message: string | null | undefined) => {
    Notify.create({ message: message ?? '' })
  },
  error: (message: string | null | undefined) => {
    Notify.create({ type: 'negative', message: message ?? '' })
  },
  warning: (message: string | null | undefined) => {
    Notify.create({ type: 'warning', message: message ?? '' })
  },
  success: (message: string | null | undefined) => {
    Notify.create({ type: 'positive', message: message ?? '' })
  },
  info: (message: string | null | undefined | NotifyOptions) => {
    if (typeof message === 'string' || message == null) {
      Notify.create({ type: 'info', message: message ?? '' })
    } else {
      Notify.create({
        type: 'info',
        message: message.message,
        actions: message.actions
          ?.map((a) => ({
            ...a,
            color: 'white'
          }))
      })
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiError: (e: any) => {
    let errorMessage = null
    if (typeof e === 'string') {
      errorMessage = e
    } else {
      errorMessage = e.response?.data?.message ?? e.body?.message
    }

    if (errorMessage == null) {
      if (typeof e.response?.data === 'string') {
        errorMessage = e.response?.data
      } else if (typeof e.body === 'string') {
        errorMessage = e.body
      }
    }

    if (errorMessage == null || errorMessage === '') {
      errorMessage = e.response?.statusText ?? e.statusText
      if (errorMessage === '' || errorMessage == null) {
        errorMessage = e.message ?? e.errorMessage
      }
    }

    Notify.create({
      type: 'negative',
      message: errorMessage
    })
  }
}
