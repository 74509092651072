export default {
  barcodeNotFound: 'Product barcode not found',
  pinnedFunctionTitle: 'Scan',
  cartItemTitle: 'Scan',
  optionRequirement: {
    required: 'Required',
    min: 'Choose {min}',
    max: 'Max {max}'
  },
  optionValidation: {
    required: 'Select one',
    min: 'Choose at least {min}',
    max: 'Choose at most {max}'
  },
  register: {
    buttonAdd: 'Add new',
    title: 'Register new product',
    barcode: 'Barcode',
    name: 'Name',
    price: 'Price',
    vatRate: 'VAT rate',
    reportCategory: 'Report category',
    success: 'Product registered'
  }
}
