export default {
  barcodeNotFound: 'Produktsträckkod hittades ej',
  pinnedFunctionTitle: 'Skanna',
  cartItemTitle: 'Skanna',
  optionRequirement: {
    required: 'Obligatorisk',
    min: 'Välj {min}',
    max: 'Max {max}'
  },
  optionValidation: {
    required: 'Välj en',
    min: 'Välj minst {min}',
    max: 'Välj max {max}'
  },
  register: {
    buttonAdd: 'Lägg till',
    title: 'Registrera ny produkt',
    barcode: 'Sträckkod',
    name: 'Namn',
    price: 'Pris',
    vatRate: 'Moms',
    reportCategory: 'Rapportkategori',
    success: 'Produkt registrerad'
  }
}
