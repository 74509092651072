export default {
  settings: 'Inställningar',
  refresh: 'Ladda om app',
  language: {
    title: 'Språk',
    description: 'Vänligen välj ett språk',
    language: 'Språk'
  },
  articleList: {
    title: 'Artikellista',
    activeArticleList: 'Aktiv artikellista',
    schedule: 'Veckoschema'
  },
  appInfo: {
    update: {
      title: 'Uppdatera app',
      description: 'Det finns en ny version av SoftPOS',
      buttonOpenStore: 'Öppna butik',
      buttonExit: 'Avsluta'
    }
  },
  printer: {
    printer: 'Skrivare',
    printTest: 'Skriv ut test',
    printerUnavailable: 'Skrivare ej tillgänglig',
    noPrinters: 'Inga skrivare',
    setDefaultPrinter: 'Gör standardskrivare',
    clearPrintQueue: 'Rensa kö'
  },
  scanner: {
    scanner: 'Skanner',
    scanTest: 'Testa skanner',
    scannerUnavailable: 'Skanner ej tillgänglig',
    noScanners: 'Inga skannrar',
    setDefaultScanner: 'Gör standardskanner'
  },
  paymentDevice: {
    paymentDevice: 'Betalterminal',
    paymentDevices: 'Betalterminaler',
    settle: 'Dagsavslut'
  },
  paymentDeviceLogType: {
    instruction: 'Instruction',
    consumer: 'Consumer',
    merchant: 'Merchant',
    lastTransaction: 'LastTransaction',
    settle: 'Settle',
    print: 'Print',
    message: 'Message',
    error: 'Error',
    receipt: 'Receipt'
  }
}
